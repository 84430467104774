.btnHablemos {
  background-color: var(--violet-dark-web-space);
  border: none;
  border-radius: 1rem;
  place-items: center;
  width: 8.8rem;
  height: 2.4rem;
  font-family: Akzidenz-Grotesk Pro Light;
  display: grid;
  position: absolute;
  top: 3.6rem;
  right: 11.2rem;
}

.btnHablemos__texto {
  background-color: var(--violet-dark-web-space);
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

p.btnHablemos__texto {
  color: #fff;
  font-family: Akzidenz-Grotesk Pro Light, sans-serif;
  font-size: 1.4rem;
  font-weight: 300;
}

.btnHablemos__redes, #btnHablemos-escritorio {
  display: none;
}

#btnHablemos-movil {
  display: flex;
}

.btnHablemos2 {
  background-color: var(--violet-dark-web-space);
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  width: 8.8rem;
  height: 2.4rem;
  font-family: Akzidenz-Grotesk Pro Light;
  text-decoration: none;
  transition: all .1s ease-in;
  display: flex;
  position: absolute;
  top: 3.6rem;
  right: 11.2rem;
}

.btnHablemos2 p {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 300;
}

.btnHablemos2:active {
  transform: scale(1.05);
}

@media (width >= 1024px) {
  #btnHablemos-escritorio {
    display: flex;
  }

  #btnHablemos-movil {
    display: none;
  }

  .btnHablemos {
    background-color: var(--violet-dark-web-space);
    border: none;
    border-radius: 1rem;
    place-items: center;
    width: 15.2rem;
    height: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    display: grid;
    position: absolute;
    top: 4.3rem;
    right: 31.2rem;
  }

  .btnHablemos__texto {
    background-color: var(--violet-dark-web-space);
    clip-path: inset(0 round 1rem 0 0 1rem);
    box-sizing: border-box;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    height: 4rem;
    padding-left: 1rem;
    padding-right: 2rem;
    transition: transform .3s;
    display: flex;
    position: absolute;
    left: 1rem;
    transform: translateX(0);
  }

  p.btnHablemos__texto {
    color: #fff;
    font-family: Akzidenz-Grotesk Pro Light, sans-serif;
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 3rem;
  }

  .btnHablemos__redes {
    border-radius: 1rem;
    align-items: center;
    width: 15.2rem;
    height: 4rem;
    display: flex;
    position: absolute;
    left: 0;
    overflow: hidden;
  }

  .btnHablemos__redes a:first-of-type {
    margin-left: 2rem;
  }

  .btnHablemos__redes a {
    opacity: 0;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem;
    transition: opacity .8s, transform .2s;
  }

  .btnHablemos:hover .btnHablemos__redes a {
    opacity: 1;
  }

  .btnHablemos__redes a:hover {
    z-index: 2;
    cursor: pointer;
    transform: scale(1.1);
  }

  .btnHablemos.hover .btnHablemos__texto {
    animation: .35s forwards hoverEffect;
  }

  .btnHablemos.leave .btnHablemos__texto {
    animation: .35s forwards hoverEffectReverse;
  }

  @keyframes hoverEffect {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(-1rem);
    }

    100% {
      transform: translateX(10.8rem);
    }
  }

  @keyframes hoverEffectReverse {
    0% {
      transform: translateX(10.8rem);
    }

    50% {
      transform: translateX(10.8rem);
    }

    100% {
      transform: translateX(0);
    }
  }
}
/*# sourceMappingURL=index.3ad45da8.css.map */
